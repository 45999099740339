import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { HitsPerPage, InstantSearch, Pagination, RefinementList } from "react-instantsearch";
import { SearchBox } from "react-instantsearch";
import {
  Alert,
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridViewIcon from "@mui/icons-material/GridView";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useHits } from "react-instantsearch";
import DefaultLayout from "../layouts/default-layout";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FilterList } from "@mui/icons-material";
import CustomClearFilterButton from "../filter/clear-filter";
import CustomFilterNumber from "../filter/filter-number";
import CustomFilterWidget from "../filter/filter-widget";
import PropTypes from "prop-types";

const searchClient = algoliasearch("JIXOFMPJNK", "a2e40ad4380b18931f58733c69b1c95b");
const primaryColor = "#003366";
const secondaryColor = "aliceblue";

function DocumentSearch({ ...props }) {
  const [searchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [initialUiState, setInitialUiState] = useState({
    [process.env.REACT_APP_ALGOLIA_REPORT_DOCUMENTS_INDEX]: {
      query: "",
      refinementList: {},
    },
  });
  const [resultView, setResultView] = useState("table");

  useEffect(() => {
    const query = searchParams.get("query") || "";
    const reportTypeRefinement = searchParams.getAll("reportType");
    const divisionRefinement = searchParams.getAll("divisionName");
    const fiscalYearRefinement = searchParams.getAll("fiscalYear");
    const page = searchParams.get("page") || 1;

    const refinementList = {};
    if (reportTypeRefinement.length > 0) {
      refinementList.reportType = reportTypeRefinement;
    }
    if (divisionRefinement.length > 0) {
      refinementList.divisionName = divisionRefinement;
    }
    if (fiscalYearRefinement.length > 0) {
      refinementList.fiscalYear = fiscalYearRefinement;
    }

    setInitialUiState({
      [process.env.REACT_APP_ALGOLIA_REPORT_DOCUMENTS_INDEX]: {
        query,
        refinementList,
        page,
      },
    });
  }, [searchParams]);

  const handleToggleResultType = (e, value) => {
    e.preventDefault();
    setResultView(value);
  };

  const filterColumns = [
    {
      filterColumnName: "Filter By Report Type",
      refinementListComponent: <RefinementList attribute="reportType" showMore searchable limit={10} />,
    },
    {
      filterColumnName: "Filter By Division",
      refinementListComponent: <RefinementList attribute="divisionName" showMore searchable />,
    },
    {
      filterColumnName: "Filter By Fiscal Year",
      refinementListComponent: (
        <RefinementList
          attribute="fiscalYear"
          limit={10}
          searchable
          sortBy={["fiscalYear", "AlphabeticalDescending"]}
          showMore
        />
      ),
    },
    {
      filterColumnName: "Filter By Quarter",
      refinementListComponent: (
        <RefinementList
          attribute="quarter"
          limit={10}
          searchable
          sortBy={["quarter", "AlphabeticalDescending"]}
          showMore
        />
      ),
    },
  ];

  return (
    <DefaultLayout title="Document Search" description="Welcome to San Bernardino County's ">
      <div className="container mt-5 mb-5">
        <Alert severity="info" icon={false} className="mb-4">
          <Typography variant="h4">Welcome to ATC&apos;s Report & Document Repository.</Typography>
          <p>
            Here you can search reports and documents by name, report type, division, and more to quickly find
            what you need.
          </p>
        </Alert>

        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.REACT_APP_ALGOLIA_REPORT_DOCUMENTS_INDEX}
          initialUiState={initialUiState}
          key={JSON.stringify(initialUiState)}
        >
          {/* Search Bar & Tools */}
          <div className="d-flex">
            <SearchBox placeholder="Search Documents" searchAsYouType className="w-100 me-2" />
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button onClick={(e) => handleToggleResultType(e, "grid")}>
                <GridViewIcon />
              </Button>
              <Button onClick={(e) => handleToggleResultType(e, "table")}>
                <TableRowsIcon />
              </Button>
            </ButtonGroup>
          </div>

          {/* Filter */}
          <div className="d-flex justify-content-center justify-content-sm-start column-gap-3 pt-4 mb-1">
            <Button
              variant="text"
              startIcon={<FilterList />}
              endIcon={<CustomFilterNumber textColor={primaryColor} {...props} isFilterOpen={isFilterOpen} />}
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
              sx={{ px: 1.5 }}
            >
              {isFilterOpen ? "Close Filters" : "Filter"}
            </Button>
            <CustomClearFilterButton />
          </div>

          <CustomFilterWidget
            filterColumns={filterColumns}
            isFilterOpen={isFilterOpen}
            optionalStyling={{ backgroundColor: secondaryColor, padding: 2, marginBottom: 2 }}
            {...props}
          />

          {/* Results */}
          {resultView === "table" ? <CustomHits {...props} /> : <CustomCardHits {...props} />}

          <div className="d-flex justify-content-between py-3">
            <HitsPerPage
              items={[
                { label: "10 hits per page", value: 10 },
                { label: "20 hits per page", value: 20, default: true },
                { label: "50 hits per page", value: 50 },
              ]}
            />
            <Pagination />
          </div>
        </InstantSearch>
      </div>
    </DefaultLayout>
  );
}
function CustomHits(props) {
  const { results } = useHits(props);
  if (!results) return null;
  const { hits } = results;

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        density="compact"
        getRowId={(row) => row.objectID}
        rows={hits}
        columns={[
          { field: "reportName", headerName: "Name", minWidth: 350 },
          { field: "reportType", headerName: "Report Type", minWidth: 350 },
          // { field: "divisionName", headerName: "Division", width: 200 },
          { field: "fiscalYear", headerName: "FY", minWidth: 200 },
          {
            field: "fileURL",
            headerName: "View",
            sortable: false,
            renderCell: (params) => {
              // console.log("params", params);
              const onClick = (e) => {
                // console.log("e", e);
                e.stopPropagation(); // don't select this row after clicking
                window.open(params.row.file, "_blank");
              };

              return (
                <Button onClick={onClick}>
                  <FontAwesomeIcon icon={faFilePdf} />
                </Button>
              );
            },
          },
          { field: "divisionName", headerName: "Division", width: 200 },
        ]}
        hideFooter={true}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}

function CustomCardHits(props) {
  const { results } = useHits(props);
  if (!results) return null;
  const { hits } = results;

  return (
    <>
      <Grid container spacing={3}>
        {hits.map((hit, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Card className="d-flex justify-content-between">
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image="/assets/images/atc5.jpg"
                alt={hit.reportName}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="body">
                    {hit.reportName}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {hit.reportType}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {hit.divisionName}
                  </Typography>
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}></Box>
              </Box>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    // console.log("e", e);
                    e.stopPropagation(); // don't select this row after clicking
                    window.open(hit.file, "_blank");
                  }}
                >
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

DocumentSearch.propTypes = {
  initialState: PropTypes.object,
};

export default DocumentSearch;
