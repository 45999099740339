import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel, Box, Typography } from '@mui/material';
import { useGAContext } from '../../_contexts/ga-context/ga-context';
import StaticPageContainer from '../static-page';

const PrivacySettings = () => {
    const { consentGiven, updateConsentGiven } = useGAContext(); // Using GA Context
    const [isTrackingEnabled, setIsTrackingEnabled] = useState(consentGiven);

    useEffect(() => {
        // Sync the switch state with the consent state from context or cookies
        const storedConsent = consentGiven
        setIsTrackingEnabled(storedConsent === true);
    }, []);

    const handleTrackingToggle = (event) => {
        const consent = event.target.checked;
        setIsTrackingEnabled(consent);
        updateConsentGiven(consent); // Update the context

        // Optionally reload the page or reset tracking
        if (!consent) {
            window['ga-disable-' + process.env.REACT_APP_GOOGLE_ANALYTICS_ID] = true; // Disable GA
        } else {
            window['ga-disable-' + process.env.REACT_APP_GOOGLE_ANALYTICS_ID] = false; // Enable GA
        }
    };

    return (
        <StaticPageContainer title="Privacy Settings">
            <Box sx={{ padding: '1rem' }}>
                {/* Always Enabled Section for Essential Cookies */}
                <Box sx={{ marginBottom: '1.5rem' }}>
                    <Typography variant="h6">
                        Essential Cookies (Always Enabled)
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        These cookies are necessary to ensure the website operates correctly
                        and cannot be disabled. They enable basic functions like page navigation and
                        access to secure areas of the site. Without these cookies, some parts of the site
                        may not work as intended.
                    </Typography>

                </Box>

                {/* Analytics Cookies Section */}
                <Box sx={{ marginBottom: '1.5rem' }}>
                    <Typography variant="h6">
                        Analytics Cookies
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isTrackingEnabled}
                                onChange={handleTrackingToggle}
                                color="primary"
                            />
                        }
                        label={isTrackingEnabled ? 'Enabled' : 'Disabled'}
                    />
                    <Typography variant="body2" color="textSecondary">
                        Analytics cookies help us understand how visitors interact with the website by collecting
                        information anonymously. You can choose to enable or disable these cookies.
                    </Typography>
                </Box>
            </Box>
            View more information about our <a href="/tax-collector/privacy-policy">Privacy Policy</a>.
        </StaticPageContainer>
    );
};

export default PrivacySettings;
