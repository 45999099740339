import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";
import StaticPage from "../../components/static-page";

const TaxCollectorPrivacyPolicy = () => {
    const [taxCollectorPrivacyPolicy, setTaxCollectorPrivacyPolicy] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage");
        const taxCollectorPrivacyPolicy = result.find((item) => item.pageContentLabel === "Tax_Collector_Privacy_Policy");
        setTaxCollectorPrivacyPolicy(taxCollectorPrivacyPolicy);
    };

    return (
        <>{taxCollectorPrivacyPolicy &&
            <StaticPage title={taxCollectorPrivacyPolicy.pageTitle}>
                {parseHtml(taxCollectorPrivacyPolicy.content)}
            </StaticPage>}
        </>
    )
}

export default TaxCollectorPrivacyPolicy;