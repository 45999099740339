import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../../helpers/helper";
import { Alert } from "@mui/material";

const DivisionHours = ({ title, hours }) => {
  return (
    <Alert
      elevation={0}
      severity="info"
      variant="outlined"
      icon={false}
      className="h-100 d-flex flex-column justify-content-center"
    >
      <h1>{title || ""}</h1>
      {/* <p>{divisionHours.description}</p> */}
      <div className="">{parseHtml(hours)}</div>
    </Alert>
  );
};

DivisionHours.propTypes = {
  title: PropTypes.string,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export default DivisionHours;
