import React, { useEffect } from "react";
import { getToken, handleLogin, getIsAuthenticated } from '../../services/auth';
import PropTypes from 'prop-types';
import scrollToTop from "../../utils/scroll-to-top-util";
import { govHubSubPath } from '../../utils/utils.js';
import { useGAContext } from "../../_contexts/ga-context/ga-context.js";

GSGWidget.propTypes = {
    initialPath: PropTypes.string,
    triggerRerender: PropTypes.bool
};

const rootIframeUrl = process.env.REACT_APP_ROOTIFRAMEURL;

function GSGWidget({ initialPath, triggerRerender }) {

    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [language, setLanguage] = React.useState("en");
    const [isDropdownRendered, setIsDropdownRendered] = React.useState(false);
    const [GATag, setGATag] = React.useState('');
    const consentGiven = useGAContext();

    useEffect(() => {
        getIsAuthenticated().then(isAuthenticated => {
            setIsAuthenticated(isAuthenticated);
        });

        const checkDropdown = setInterval(() => {
            const languageDropdown = document.getElementsByClassName("gt_selector")[0];
            if (languageDropdown) {
                clearInterval(checkDropdown);
                setIsDropdownRendered(true);
                languageDropdown.addEventListener('change', handleLanguageDropdownChange);
                handleLanguageDropdownChange(); // Ensure initial load checks language
            }
        }, 100);

        return () => {
            clearInterval(checkDropdown);
            const languageDropdown = document.getElementsByClassName("gt_selector")[0];
            if (languageDropdown) {
                languageDropdown.removeEventListener('change', handleLanguageDropdownChange);
            }
        };
    }, []);

    useEffect(() => {
        if (isDropdownRendered) {
            exeJs();
        }
    }, [initialPath, isAuthenticated, triggerRerender, language, isDropdownRendered, consentGiven]);

    useEffect(() => {
        if (consentGiven.consentGiven) {
            setGATag(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        }
        else {
            setGATag('');
        }
    }, [consentGiven]);

    const handleUpdateURL = (newPath) => {
        newPath = '/' + govHubSubPath + newPath;
        window.history.replaceState(null, '', newPath);
    };

    const handleLanguageDropdownChange = async () => {
        const selectedValue = document.getElementsByClassName("gt_selector")[0].value.substring(5);
        selectedValue === "es" ? setLanguage("es") : setLanguage("en");
    };

    const exeJs = () => {
        console.log("Executing GSG Public Site JS");
        window.GsgPublicSite.attach({
            site: "ca-sanbernardino/ca-sanbernardino",
            rootIframeUrl: rootIframeUrl,
            initialPath: initialPath,
            language: language,
            googleAnalytics: {
                googleTagId: GATag,
                userId: "",
            },
            elementSelector: "#gsg-embedded-public-site",
            onPathChange: (newPath) => {
                // ...update your page's path with newPath
                //TODO: Discuss with Team on how to leverage this functionality for tracking where the user is within the embedded site
                handleUpdateURL(newPath);
                scrollToTop();
            },
            getJwt: () => {
                if (isAuthenticated) {
                    return getToken().then(token => {
                        const jwt = token;
                        return jwt;
                    });
                }
            },
            handleLogin: () => {
                !isAuthenticated &&
                    handleLogin();
            }
        });
    };

    return (<>
        <div className="gsg-container">
            <div className="container mt-5">
                <div className="row">
                    <div id="gsg-embedded-public-site"></div>
                </div>
            </div>
        </div>
    </>
    );
}

export default GSGWidget;
