import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MainSection from '../main-section'
import { Fade } from "react-awesome-reveal";
import { useParallax } from 'react-scroll-parallax';
import { Button } from '@mui/material';
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";

const MeetYourATC = () => {
    const header = useParallax({ scale: [1.15, 1.25, 'easeInQuad'] })
    const [atcInformation, setAtcInformation] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("TeamMember/ADM");
        const atc = result.find(item => item.position === "Auditor-Controller/Treasurer/Tax Collector");
        setAtcInformation(atc);
    };

    return (
        <div className="meet-your-atc h-100">
            <div className="custom-shape-divider-top-1677101761">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div>
            <MainSection backgroundClass="background-light" className="mb-5">

                <div className='d-flex justify-content-center align-items-center'>
                    <div className='container'>
                        <div className='d-flex justify-content-center mt-5 mb-5'>
                            <p className='display-6 text-center title' ref={header.ref}>Meet Your Auditor-Controller/Treasurer/Tax Collector</p>

                        </div>
                        {atcInformation &&
                            <div className='row mb-5 px-0 px-xl-5 py-3'>
                                <div className='col-lg-4 px-0 py-4'>
                                    <Fade triggerOnce direction="right" className="order-1 h-100">
                                        <div className="d-flex justify-content-center align-items-center h-100 pb-0 pb-lg-5">
                                            <img src={atcInformation.imageUrl} alt={atcInformation.position} className='atc-img img-fluid' />
                                        </div>
                                    </Fade>
                                </div>
                                <div className='col-lg-8 px-3 px-md-5'>
                                    <Fade triggerOnce direction="left" className="order-2" >
                                        <h4 className="fw-normal" > {atcInformation ? `${atcInformation.firstName} ${atcInformation.lastName} ${atcInformation.suffix}` : ''} </h4>
                                        {parseHtml(atcInformation.biography)}

                                    </Fade>
                                </div>
                                <Fade triggerOnce direction="left">
                                    <div className='text-center p-3 pb-5'>
                                        <Button component={Link} to="/exec-team" variant='contained'>Meet My Team!</Button>
                                    </div>
                                </Fade>
                                <Fade triggerOnce direction="right">
                                    <div className='text-center pt-5'>
                                        <div>
                                            <iframe
                                                width="100%"
                                                src={`https://www.youtube.com/embed/tJ6GKgjVFOI?rel=0`}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded youtube"
                                                style={{ maxWidth: "600px", gridRow: "1", gridColumn: "1", aspectRatio: "16/9" }}
                                            />
                                        </div>
                                        <div className='p-4'>
                                            <Button component={Link} to="/about" variant="contained">Click here to learn more!</Button>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        }
                    </div>
                </div>
            </MainSection>
            <div className="custom-shape-divider-bottom-1677101896">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div>
        </div>
    )
}

export default MeetYourATC