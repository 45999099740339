import { ListItemButton, ListItemIcon, ListItemText, Box, Button, Typography, List, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom/dist";
import PropTypes from "prop-types";

const textColor = "#444444";

const MenuSubHeader = ({ title, icon, onClick, openDivisions, openOnlineServices, openGeneralInfo, ...props }) => (
  <Box>
    <Button
      variant="text"
      component={RouterLink}
      onClick={onClick}
      sx={{ width: "100%", justifyContent: "space-between", fontWeight: 700, px: 1, py: 2 }}
      {...props}
    >
      <div className="d-flex">
        <ListItemIcon sx={{ minWidth: "38px", color: "#1c6889" }}> {icon} </ListItemIcon>
        <Typography sx={{ color: "#1c6889" }}>{title}</Typography>
      </div>
      {title === "Online Services" && (openOnlineServices ? <ExpandLess /> : <ExpandMore />)}
      {title === "Divisions" && (openDivisions ? <ExpandLess /> : <ExpandMore />)}
      {title === "General Information" && (openGeneralInfo ? <ExpandLess /> : <ExpandMore />)}
    </Button>
  </Box>
);

const CustomCollapse = ({ children, isOpen, list, onClick, ...props }) => (
  <Collapse in={isOpen} timeout="auto" unmountOnExit>
    <List component="div" disablePadding sx={{ mb: 2 }}>
      {list.map((itemGroup, j) => (
        <div key={j} className="pb-2">
          {itemGroup.subheader && (
            <CustomMenuItem onClick={onClick} to={itemGroup.subheaderLink}>
              {itemGroup.subheader}
            </CustomMenuItem>
          )}
          {itemGroup.items.map((item, k) => (
            <CustomListItem key={k} to={item.itemLink} onClick={onClick}>
              {item.item}
            </CustomListItem>
          ))}
        </div>
      ))}
    </List>
  </Collapse>
);

const CustomMenuItem = ({ children, onClick, ...props }) => (
  <ListItemButton component={RouterLink} onClick={onClick} sx={{ pl: 3, py: 0.5 }} {...props}>
    <ListItemText
      primary={children}
      primaryTypographyProps={{ fontWeight: "bold", color: textColor, fontSize: "95%" }}
      sx={{ textTransform: "uppercase" }}
    />
  </ListItemButton>
);

const CustomListItem = ({ children, onClick, ...props }) => (
  <ListItemButton component={RouterLink} onClick={onClick} sx={{ pl: 5, py: 0.5 }} {...props}>
    <ListItemText primary={children} primaryTypographyProps={{ color: textColor }} />
  </ListItemButton>
);

MenuSubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  openDivisions: PropTypes.bool,
  openOnlineServices: PropTypes.bool,
  openGeneralInfo: PropTypes.bool,
};

CustomCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

CustomMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

CustomListItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { MenuSubHeader, CustomMenuItem, CustomListItem, CustomCollapse };
