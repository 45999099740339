import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";
import StaticPage from "../../components/static-page";

const IndependenceOfTheAuditorFunction = () => {
    const [independenceOfTheAuditorFunction, setIndependenceOfTheAuditorFunction] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage");
        const independenceOfTheAuditorFunction = result.find(
            (item) => item.pageContentLabel === "Independence_of_the_Auditor_Function"
        );

        setIndependenceOfTheAuditorFunction(independenceOfTheAuditorFunction);
    };

    return (
        independenceOfTheAuditorFunction && (
            <StaticPage title={independenceOfTheAuditorFunction.pageTitle}>
                {parseHtml(independenceOfTheAuditorFunction.content)}
            </StaticPage>
        )
    );
};

export default IndependenceOfTheAuditorFunction;