import AlertService from "../../components/alert-service/alert-service";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import LinkActionCard from "../../components/action-card/link-action-card";
import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import PropTypes from "prop-types";

function PropertyTaxHome({ division }) {
  const [serviceTiles, setServicesTiles] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
      const result = (await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`))
          .filter(service => !service.isMoreServices) 
          .sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
    setServicesTiles(result);
  };

  return (
    <div>
      <AlertService divisionCode={division.divisionAbbreviation} />

      {/* Services */}
      <div className="container grid-container grid-style-4 py-5 mb-5">
        {serviceTiles &&
          serviceTiles.map((item, index) => (
            <LinkActionCard
              key={index}
              title={item.serviceLabel}
              linkDestination={item.link}
              cardColor={item.color}
              target={item.link.startsWith("http") ? "_blank" : ""}
            />
          ))}
      </div>

      {/* <DivisionServices
        divisionAbbreviation={division.divisionAbbreviation}
        iconList={iconList}
        sectionTitle="Our Services"
      /> */}

      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/property-tax/about"
      />
      <DivisionContact
        divisionAbbreviation={division.divisionAbbreviation}
        sectionTitle={"Contact Property Tax"}
      />
    </div>
  );
}

PropertyTaxHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default PropertyTaxHome;
