import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";
import StaticPage from "../../components/static-page";
import "./index.scss";

const TaxCollectorClosureSchedule = () => {
  const [taxCollectorClosureHours, setTaxCollectorClosureHours] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage");
    const taxCollectorClosureHours = result.find(
      (item) => item.pageContentLabel === "Tax_Collector_Closure_Schedule"
    );
    setTaxCollectorClosureHours(taxCollectorClosureHours);
  };

  return (
    <StaticPage title="Tax Collector Closure Schedule">
      <div className="closure-hours-table d-flex flex-column align-items-center">
        {taxCollectorClosureHours && parseHtml(taxCollectorClosureHours.content)}
      </div>
    </StaticPage>
  );
};

export default TaxCollectorClosureSchedule;