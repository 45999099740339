import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Collapse, CssBaseline, Divider, Drawer, IconButton, List } from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { divisionItemsList } from "../../utils/division-items";
import { onlineServiceItemsList } from "../../utils/online-services-items";
import { generalInfoItemsList } from "../../utils/general-info-items";
import { navbarList } from "../../utils/navbar-items";
import LoginButtonContainer from "../../containers/login-button-container";
import { MenuSubHeader, CustomMenuItem, CustomListItem, CustomCollapse } from "./navbar-items";

const drawerWidth = "400px";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const [open, setOpen] = useState(false);
  const [openOnlineServices, setOnlineServicesOpen] = useState(false);
  const [openDivisions, setDivisionsOpen] = useState(false);
  const [openGeneralInfo, setGeneralInfoOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDivisions = () => {
    setDivisionsOpen(!openDivisions);
  };
  const handleOnlineServices = () => {
    setOnlineServicesOpen(!openOnlineServices);
  };
  const handleGeneralInfo = () => {
    setGeneralInfoOpen(!openGeneralInfo);
  };

  return (
    <>
      <CssBaseline />
      <div className="nav-logo-wrapper">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ height: "100%", mx: 2, p: 0 }}
        >
          <Menu />
          <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
        </IconButton>
        <LoginButtonContainer isDrawer={false} />
      </div>

      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          zIndex: 1000000,
        }}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            color: "black",
          },
        }}
      >
        <DrawerHeader
          className="nav-logo-wrapper justify-content-between"
          sx={{ backgroundColor: "#003366" }}
          onClick={handleDrawerClose}
        >
          <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
          <IconButton onClick={handleDrawerClose} sx={{ mx: 1 }}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </DrawerHeader>
        <div className="d-flex flex-column justify-content-between h-100">
          <List sx={{ px: 2 }}>
            {navbarList.map((item, i) => (
              <div key={i}>
                <MenuSubHeader
                  title={item.subheader}
                  icon={item.icon}
                  to={item.subheaderLink}
                  onClick={() => {
                    item.subheader === "Online Services"
                      ? handleOnlineServices()
                      : item.subheader === "Divisions"
                      ? handleDivisions()
                      : item.subheader === "General Information"
                      ? handleGeneralInfo()
                      : handleDrawerClose();
                  }}
                  openDivisions={openDivisions}
                  openOnlineServices={openOnlineServices}
                  openGeneralInfo={openGeneralInfo}
                />

                {item.subheader === "Online Services" && (
                  <CustomCollapse
                    isOpen={openOnlineServices}
                    list={onlineServiceItemsList}
                    onClick={handleDrawerClose}
                  />
                )}

                {item.subheader === "Divisions" && (
                  <CustomCollapse
                    isOpen={openDivisions}
                    list={divisionItemsList}
                    onClick={handleDrawerClose}
                  />
                )}

                {item.subheader === "General Information" && (
                  <CustomCollapse
                    isOpen={openGeneralInfo}
                    list={generalInfoItemsList}
                    onClick={handleDrawerClose}
                  />
                )}

                <Divider />
              </div>
            ))}
            <LoginButtonContainer isDrawer={true} handleDrawerClose={handleDrawerClose} />
          </List>
        </div>
      </Drawer>
    </>
  );
}
