import React, { useEffect, useState } from "react";
import InformationalTiles from "../../components/treasurer/informational-tiles";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";

const apiName = "Webpage";
const pageType = "Legally Mandate Duties";

const LegallyMandatedDuties = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`${apiName}/${pageType}`);
    setData(result);
  };

  return (
    <StaticPageContainer title={pageType}>
      {data.map((item, i) => (
        <InformationalTiles
          key={i}
          title={item.pageContentLabel}
          icon={item.contentImageUrl}
          content={parseHtml(item.content)}
          side={i % 2 === 0 ? "left" : "right"}
        />
      ))}
    </StaticPageContainer>
  );
};

export default LegallyMandatedDuties;
