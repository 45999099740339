import AlertService from "../../components/alert-service/alert-service";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import DivisionServices from "../../components/division-homepage/division-services";
import { MdReport } from "react-icons/md";
import { RiHomeGearFill } from "react-icons/ri";
import { RiUserSearchFill } from "react-icons/ri";
import PropTypes from "prop-types";

const iconList = [MdReport, RiHomeGearFill, RiUserSearchFill];

function AuditorHome({ division }) {
    return (
        <div>
            <AlertService divisionCode={division.divisionAbbreviation} />
            <DivisionServices divisionAbbreviation={division.divisionAbbreviation} iconList={iconList} sectionTitle="Our Services" />
            <DivisionAboutBanner
                divisionAbbreviation={division.divisionAbbreviation}
                divisionTag={division.tag}
                linkTo="/internal-audits/about"
            />
            <DivisionContact divisionAbbreviation={division.divisionAbbreviation} sectionTitle={"Contact Internal Audits"} />
        </div>
    );
}

AuditorHome.propTypes = {
    division: PropTypes.shape({
        divisionAbbreviation: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
    }).isRequired,
};

export default AuditorHome;
