import React, { useEffect, useRef, useState } from "react";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";
import Loader from "../loader";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const apiName = "DivisionService";
const iconSize = "100px";

function DivisionServices({ divisionAbbreviation, iconList, sectionTitle }) {
  const [serviceTiles, setServicesTiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const divRefs = useRef([]);

  useEffect(() => {
    const maxHeight = Math.max(...divRefs.current.map((ref) => ref.getBoundingClientRect().height));
    divRefs.current.forEach((ref) => {
      ref.style.height = `${maxHeight}px`;
    });
  }, [serviceTiles]);

  useEffect(() => {
    getData().then(() => setIsLoading(false));
  }, []);

  const getData = async () => {
    const result = (await getDataFromCmsApi(`${apiName}/${divisionAbbreviation}`))
      .sort((a, b) => a.serviceId - b.serviceId)
      .map((item, i) => {
        if (iconList) {
          const IconComponent = iconList[i];
          if (IconComponent) {
            return { ...item, icon: <IconComponent style={{ fontSize: iconSize }} /> };
          }
        }
        return item;
      });
    setServicesTiles(result);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="container d-flex flex-column justify-content-center px-3 px-lg-5 py-5">
      <h1 className="display-4 text-center py-3">{sectionTitle}</h1>
      <div className="row justify-content-center">
        {serviceTiles.map((item, i) => (
          <div key={i} className="col-12 col-sm-6 col-lg-4 col-xl-3 p-3 my-2">
            <Card
              className="h-100"
              sx={{
                borderRadius: 2,
                "&:hover": {
                  transform: item.link ? "scale(1.05)" : "none",
                },
              }}
            >
              <CardActionArea
                ref={(el) => (divRefs.current[i] = el)}
                className="p-3 text-center text-white"
                disabled={!item.link}
                sx={{ backgroundColor: item.color, borderRadius: "10px 10px 0 0" }}
                component={Link}
                to={item.link}
                // target={item.link ? "_blank" : ""}
                rel="noopener noreferrer"
              >
                <div className="py-3">{item.icon}</div>
                <h3 className="py-3">{item.serviceLabel}</h3>
              </CardActionArea>
              <CardContent className="p-3">{parseHtml(item.serviceDescription)}</CardContent>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

DivisionServices.propTypes = {
  divisionAbbreviation: PropTypes.string.isRequired,
  iconList: PropTypes.arrayOf(PropTypes.elementType),
  sectionTitle: PropTypes.string,
};

export default DivisionServices;
