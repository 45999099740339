import React from 'react';
import './env-banner.scss';

const EnvBanner = () => {
    const environment = process.env.REACT_APP_ENV;

    if (environment === 'production') {
        return null; // Don't display the banner in production
    }

    return (
        <div className={`${environment === 'production' ? '' : ((environment == 'qa') ? 'qa' : 'dev')} env-banner`}>
            <p>You are currently in the <b className='upper-case'>{environment}</b> environment.</p>
        </div>
    );
};

export default EnvBanner;