import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardActionArea, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import React from "react";
import { Link } from "react-router-dom";

const LinkActionCard = ({ title, linkTitle, linkDestination, cardColor, children, target }) => {
    return (
        <Card className={`shadow-lg w-100 h-100 border-0 link-action-card dark text-wrap`} style={{ backgroundColor: cardColor }}>
            <CardActionArea className="h-100" component={Link} to={linkDestination} aria-label={linkTitle} target={target}>
                <CardContent className="h-100 d-flex flex-column">
                    <Typography className="card-title" gutterBottom variant="h4" component="div">
                        {title}
                    </Typography>
                    <div className="card-body">
                        {children}
                    </div>
                    <div className="p-2 d-flex justify-content-end">
                        <div className="float-end">   <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

LinkActionCard.propTypes = {
    title: PropTypes.string.isRequired,
    linkTitle: PropTypes.string,
    linkDestination: PropTypes.string.isRequired,
    cardColor: PropTypes.string,
    children: PropTypes.node,
    target: PropTypes.string

}

export default LinkActionCard