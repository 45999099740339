import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Button, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";

function AlertService({ divisionCode }) {
    const [alertList, setAlertList] = useState(null);
    const [isOpenList, setIsOpenList] = useState(false);

    useEffect(() => {
        getData(divisionCode);
    }, [divisionCode]);

    const getData = async () => {
        const result = await getDataFromCmsApi("/Alert/" + divisionCode);
        setAlertList(result);
        setIsOpenList(result.map(() => true));
    };

    return (
        alertList && alertList.length > 0 && (
            <div className="py-4">
                {alertList && alertList.map((alert, i) => (
                    <Collapse key={i} in={isOpenList[i]}>
                        <Alert
                            className="mb-2"
                            //icon={false}
                            severity={alert.alertTypeCode}
                            sx={{ fontSize: "19px" }}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => {

                                        setIsOpenList([(isOpenList[i] = false), ...isOpenList].slice(1));
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            <AlertTitle sx={{ fontSize: "23px" }}> {parseHtml(alert.title)} </AlertTitle>
                            {parseHtml(alert.description)}
                        </Alert>
                    </Collapse>
                ))}
                {alertList && alertList.length > 0 && isOpenList && isOpenList.every((i) => i === false) && (
                    <Button
                        variant="outlined"
                        startIcon={<FeedbackIcon />}
                        onClick={() => {
                            setIsOpenList(isOpenList.map((item) => true));
                        }}
                    >
                        Re-open alerts
                    </Button>
                )}
            </div>
        )
    );
}


AlertService.propTypes = {
    divisionCode: PropTypes.string,
};


export default AlertService;
