import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DowJones from "../../components/treasurer/dow-jones";
import TreasuryYieldGraph from "../../components/treasurer/market-update";
import NewsTicker from "../../components/treasurer/news-ticker";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import _ from "lodash";
import { getDataFromCmsApi } from "../../api/contentful-service";
import LinkActionCard from "../../components/action-card/link-action-card";
import DivisionContact from "../../components/division-homepage/division-contact";
import DivisionHours from "../../components/division-homepage/division-hours";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";

function TreasurerHome({ division }) {
    const [services, setServices] = useState(null);
    const [hours, setHours] = useState(null);

    useEffect(() => {
        getServices();
        getHours().then((response) => {

            setHours(response)
        });
    }, []);

    const getServices = async () => {
        const services = await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`);
        const sortedServices = services
            .filter(service => !service.isMoreServices) 
        setServices(sortedServices.sort((a, b) => a.sortOrder - b.sortOrder));
    };

    const getHours = async () => {
        let result = await getDataFromCmsApi("atcHoursOfOperation");

        result = result.filter((item) => {
            return item.divisionAbbreviation === division.divisionAbbreviation;
        });
        return _.head(result);
    }

    return (
        <div>
            <div className="container mt-4">
                <AlertService divisionCode={division.divisionAbbreviation} />
            </div>
            <Box className="container d-flex flex-column justify-content-center treasurer mt-5">
                <Typography variant="h2" className="text-center">
                    Our Services
                </Typography>
                <Box sx={{ minHeight: '500px' }} className="container grid-container grid-style-4 py-5">
                    {services && services.map((item, index) => (
                        <LinkActionCard
                            key={index}
                            title={item.serviceLabel}
                            linkDestination={item.link}
                            cardColor={item.color}
                        />
                    ))}
                </Box>
            </Box>
            <div className="container d-flex flex-column justify-content-center treasurer my-5">
                {hours &&
                    <DivisionHours divisionAbbreviation={division.divisionAbbreviation} hours={hours.treasurerOfficeHours} />
                }
            </div>
            <div className="my-5 py-5">
                <DivisionAboutBanner className="mb-4"
                    divisionAbbreviation={division.divisionAbbreviation}
                    divisionTag={division.tag}
                    linkTo='/treasurer/about'
                />
            </div>
            <div className="container treasurer ">
                <div className="row downjones-graph  my-2 pb-5">
                    <Typography variant="h4" mb={1} color="gray">
                        Dow Jones
                    </Typography>
                    <DowJones />
                </div>
                <Divider />
                <div className="row my-2 pb-5">
                    <Typography variant="h4" mb={1} color="gray">
                        Treasury Market Update
                    </Typography>
                    <TreasuryYieldGraph />
                </div>
                <div className="row downjones-graph pb-5">
                    <DivisionContact divisionAbbreviation={division.divisionAbbreviation} sectionTitle={"Contact Treasurer"} hasForm={false} />
                </div>
            </div>
            <NewsTicker />
        </div>
    );
}

TreasurerHome.propTypes = {
    division: PropTypes.object.isRequired,
};

export default TreasurerHome;
