import React, { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";



function InvestmentPool() {
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`Webpage`);
    const investmentPool = result.find(
      (item) => item.pageContentLabel === "Treasurer_Management_of_the_Investment_Pool"
    );
    setData(investmentPool);
  };

  return (
    data &&
    <StaticPageContainer title={data.pageTitle && parseHtml(data.pageTitle)}>
      {data.content && parseHtml(data.content)}
    </StaticPageContainer>
  );
}

export default InvestmentPool;
