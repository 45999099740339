import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom/dist";
import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import NavbarDrawer from "./navbar-drawer";
import LoginButtonContainer from "../../containers/login-button-container";
import { divisionItemsList } from "../../utils/division-items";
import { onlineServiceItemsList } from "../../utils/online-services-items";
import MenuTemplate from "./styled-menus";
import { navbarList } from "../../utils/navbar-items";
import ShoppingCart from "./shopping-cart";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { generalInfoItemsList } from "../../utils/general-info-items";

function Navigation() {
  const [isOnlineServicesOpen, setIsOnlinesServicesOpen] = useState(false);
  const [isDivisionsOpen, setIsDivisionsOpen] = useState(false);
  const [isGeneralInfoOpen, setIsGeneralInfoOpen] = useState(false);
  const [bottomAnchor, setBottomAnchor] = useState(null);
  const navbarRef = useRef(null);

  const handleDivisionClick = () => {
    setIsDivisionsOpen(true);
    setIsOnlinesServicesOpen(false);
    setIsGeneralInfoOpen(false);
  };

  const handleOnlineServicesClick = () => {
    setIsOnlinesServicesOpen(true);
    setIsDivisionsOpen(false);
    setIsGeneralInfoOpen(false);
  };

  const handleGeneralInfoClick = () => {
    setIsGeneralInfoOpen(true);
    setIsOnlinesServicesOpen(false);
    setIsDivisionsOpen(false);
  };

  const handleCloseMenu = () => {
    setIsDivisionsOpen(false);
    setIsOnlinesServicesOpen(false);
    setIsGeneralInfoOpen(false);
  };

  useEffect(() => {
    if (navbarRef.current) {
      const bottomPosition = navbarRef.current.getBoundingClientRect().bottom;
      setBottomAnchor(bottomPosition);
    }
  }, []);

  const useStyles = {
    button: {
      color: "white",
      textAlign: "center",
      height: "100%",
      "&:hover": {
        color: "#cd9e0c",
        // textDecoration: "underline",
      },
    },
  };

  return (
    <AppBar ref={navbarRef} className="position-sticky">
      <Toolbar disableGutters>
        <Box className="nav-wrapper" sx={{ display: { xs: "none", md: "flex" } }}>
          {/* logo */}
          <IconButton className="nav-logo-wrapper" edge="start" color="inherit" aria-label="menu">
            <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
          </IconButton>

          {/* nav links */}
          <div className="d-flex mx-3 justify-content-around navbar-buttons">
            {navbarList.map((item, i) => (
              <Button
                key={i}
                variant="Text"
                component={RouterLink}
                to={item.subheaderLink}
                onMouseOver={
                  item.subheader === "Online Services"
                    ? handleOnlineServicesClick
                    : item.subheader === "Divisions"
                    ? handleDivisionClick
                    : item.subheader === "General Information"
                    ? handleGeneralInfoClick
                    : handleCloseMenu
                }
                onMouseLeave={handleCloseMenu}
                sx={useStyles.button}
              >
                {item.subheader}
                {item.subheader === "Online Services" ? (
                  isOnlineServicesOpen ? (
                    <ExpandMore className="ms-1" />
                  ) : (
                    <ExpandLess className="ms-1" />
                  )
                ) : null}
                {item.subheader === "Divisions" ? (
                  isDivisionsOpen ? (
                    <ExpandMore className="ms-1" />
                  ) : (
                    <ExpandLess className="ms-1" />
                  )
                ) : null}
                {item.subheader === "General Information" ? (
                  isGeneralInfoOpen ? (
                    <ExpandMore className="ms-1" />
                  ) : (
                    <ExpandLess className="ms-1" />
                  )
                ) : null}
              </Button>
            ))}

            {/* menus */}
            <MenuTemplate
              id="online-services-menu"
              isOpen={isOnlineServicesOpen}
              handleOpenMenu={handleOnlineServicesClick}
              handleCloseMenu={handleCloseMenu}
              itemsList={onlineServiceItemsList}
              bottomAnchor={bottomAnchor}
            />
            <MenuTemplate
              id="divisions-menu"
              isOpen={isDivisionsOpen}
              handleOpenMenu={handleDivisionClick}
              handleCloseMenu={handleCloseMenu}
              itemsList={divisionItemsList}
              bottomAnchor={bottomAnchor}
            />
            <MenuTemplate
              id="general-info-menu"
              isOpen={isGeneralInfoOpen}
              handleOpenMenu={handleGeneralInfoClick}
              handleCloseMenu={handleCloseMenu}
              itemsList={generalInfoItemsList}
              bottomAnchor={bottomAnchor}
            />
          </div>
          <div className="d-flex px-5">
            <LoginButtonContainer isDrawer={false} />
            <ShoppingCart></ShoppingCart>
          </div>
        </Box>
        <Box className="nav-wrapper" sx={{ display: { sm: "flex", md: "none" } }}>
          <NavbarDrawer drawerWidth={0}></NavbarDrawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
Navigation.propTypes = {};
export default Navigation;
