export const cardColors = {
  one: "#0A294D",
  two: "#C98E2E",
  three: "#1C6889",
  four: "#518466",
  five: "#B5552C",
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const titleFontSize = "calc(20px + 0.6vw)";
export const subtitleFontSize = "calc(19px + 0.5vw)";
export const textFontSize = "calc(15px + 0.3vw)";

export const govHubSubPath = "tax-services";
