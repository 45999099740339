import React from "react";
import { FaAward } from "react-icons/fa";
import { BsClipboard2DataFill, BsFillQuestionCircleFill } from "react-icons/bs";
import { MdDeveloperBoard } from "react-icons/md";
import DivisionServices from "../../components/division-homepage/division-services";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";

const iconList = [MdDeveloperBoard, BsClipboard2DataFill, BsFillQuestionCircleFill, FaAward];

function ITHome({ division }) {
  return (
    <>
      <AlertService divisionCode={division.divisionAbbreviation} />
      <DivisionServices
        divisionAbbreviation={division.divisionAbbreviation}
        iconList={iconList}
        sectionTitle="Our Services"
      />
      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/information-technology/about"
      />
      <DivisionContact
        divisionAbbreviation={division.divisionAbbreviation}
        sectionTitle={"Contact ITD"}
        formLink="/"
      />
    </>
  );
}

ITHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default ITHome;
