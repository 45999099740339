import { RiCustomerServiceFill } from "react-icons/ri";

export const onlineServiceItemsList = [
  {
    subheader: "Services",
    items: [
      { item: "Get Electronic Tax Bills", itemLink: "#" },
      { item: "Online Documents", itemLink: "/search/documents" },
      { item: "Pay your Taxes Online", itemLink: "/tax-collector/pay-online" },
      { item: "Unclaimed Property Tax Refund", itemLink: "/tax-services/property-tax" },
      { item: "Customer Satisfaction Survey", itemLink: "/forms/customer-satisfaction" },
      { item: "Report Fraud & Waste", itemLink: "#" },
    ],
    icon: <RiCustomerServiceFill />,
  },
];
