import React, { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import DivisionGroupContact from "../../components/hours-and-locations/division-contact";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function HoursAndLocationsPage() {
    const [locationImage, setLocationImage] = useState([]);
    const [googleDirectionsLink, setGoogleDirectionsLink] = useState([]);
    const [divisionGroupContacts, setDivisionGroupContacts] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("DivisionContact");
        const filteredResult = result.filter((item) => item.divisionGroupID !== -1); // these are the grouped divisions

        setLocationImage(filteredResult[0].locationImage);
        setGoogleDirectionsLink(filteredResult[0].googleDirectionsLink);
        setDivisionGroupContacts(filteredResult);
        console.log(filteredResult);
    };

    return (
        <div className="contact-us container overflow-hidden py-5">
            <title>Welcome to the San Bernardino County Auditor-Controller/Treasurer/Tax Collector</title>
            <meta
                name="description"
                content="This office is committed to serving our customers by processing, safeguarding, and providing information regarding the finances and public records of the County. We perform these functions with integrity, independent judgment, and outstanding service. We are accurate, timely, courteous, innovative, and efficient because of our well-trained and accountable staff. "
                key="desc"
            />

            <div className="container px-md-5">
                <div className="d-flex justify-content-center mb-4 mb-lg-5">
                    <p className="display-6 text-center title">Hours, Locations, and Contact Numbers</p>
                </div>

                <div className="row d-flex">
                    <div className="col-12 col-lg-6">
                        <img src={locationImage ? `https:${locationImage}` : ""} alt="" style={{ width: "100%" }} />
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                        <iframe
                            src={googleDirectionsLink ? googleDirectionsLink : ""}
                            width="100%"
                            height="100%"
                            allowFullScreen=""
                            loading="lazy"
                            title="ATC San Bernardino, California, USA"
                        ></iframe>
                    </div>
                </div>

                <div className="row mt-4 mb-3 d-flex justify-content-center">
                    {divisionGroupContacts &&
                        divisionGroupContacts.map((item, i) => (
                            <div key={i} className="col-12 col-lg-6 col-xl-4">
                                <div className="d-flex h-100">
                                    <DivisionGroupContact
                                        divisionContact={item}
                                        divisionAbbreviation={
                                            item.divisionGroupID === 1 ? "TRE" : item.divisionGroupID === 2 ? "TXC" : null
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="text-center pb-2">
                <Button component={Link} variant="contained" color="primary" to="/contact-us">
                    Contact Us Online
                </Button>
            </div>
        </div>
    );
}
export default HoursAndLocationsPage;
