import React, { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";

function ContactPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`Webpage`);
    const contactInfo = result.find((item) => item.pageContentLabel === "Treasurer_Contact_Information");
    setData(contactInfo);
  };

  return (
    <StaticPageContainer title={data.pageTitle && parseHtml(data.pageTitle)}>
      {data.content && parseHtml(data.content)}
    </StaticPageContainer>
  );
}

export default ContactPage;