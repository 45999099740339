import { Box, Collapse, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const CustomFilterColumn = ({ children, filterColumnName }) => {
  return (
    <div className="pb-2">
      <Typography variant="overline" component="h1" gutterBottom>
        {filterColumnName}
      </Typography>
      {children}
    </div>
  );
};

const CustomFilterWidget = ({ isFilterOpen, filterColumns, optionalStyling }) => {
  return (
    <Collapse in={isFilterOpen} sx={optionalStyling}>
      <Box className="d-block d-md-flex" sx={{ columnGap: "4rem" }}>
        {filterColumns && filterColumns.map((column, index) => (
          <CustomFilterColumn key={index} filterColumnName={column.filterColumnName}>
            {column.refinementListComponent}
          </CustomFilterColumn>
        ))}
      </Box>
    </Collapse>
  );
};


CustomFilterWidget.propTypes = {
  isFilterOpen: PropTypes.bool.isRequired,
  filterColumns: PropTypes.array.isRequired,
  optionalStyling: PropTypes.object,
};

CustomFilterColumn.propTypes = {
  children: PropTypes.node.isRequired,
  filterColumnName: PropTypes.string.isRequired,
};


export default CustomFilterWidget;
