import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";
import StaticPage from "../../components/static-page";

const ReportFraudWasteAbuse = () => {
    const [reportFraudWasteAbuse, setReportFraudWasteAbuse] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage");
        const reportFraudWasteAbuse = result.find(
            (item) => item.pageContentLabel === "Report_Fraud_Waste_Abuse"
        );

        setReportFraudWasteAbuse(reportFraudWasteAbuse);
    };

    return (
        reportFraudWasteAbuse && (
            <StaticPage title={reportFraudWasteAbuse.pageTitle}>
                {parseHtml(reportFraudWasteAbuse.content)}
            </StaticPage>
        )
    );
};

export default ReportFraudWasteAbuse;