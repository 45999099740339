import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Chip,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../helpers/helper";

const apiName = "ImportantDate";

function ImportantDates() {
  const [importantDates, setImportantDates] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(apiName);
    result.map((item) => {
      item.serviceDescription = parseHtml(item.serviceDescription);
      return item;
    });
    setImportantDates(result);
  };

  return (
    <Box className="container" sx={{ padding: { xs: "50px 20px", lg: "50px 150px", xl: "50px 250px" } }}>
      <p className="display-4 text-center pb-4">Important Dates</p>
      <TableContainer>
        <Table>
          <TableBody>
            {importantDates &&
              importantDates.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    className="pb-0"
                    component="th"
                    scope="row"
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    <Chip
                      className="mb-3 w-100"
                      color="primary"
                      variant="outlined"
                      label={`${row.month} ${row.day !== 0 ? row.day : ""}`}
                    />
                  </TableCell>

                  <TableCell className="pb-0" style={{ fontSize: "medium" }}>
                    {row.serviceDescription}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="p-5 text-center">
        <h5 className="pb-4 fw-bold">
          (*) If a delinquent date falls on a weekend or holiday, the delinquent date is the next business
          day.
        </h5>

        <MuiLink
          underline="hover"
          component={Link}
          to="/"
          sx={{ fontSize: "larger", fontWeight: "bold", textTransform: "uppercase" }}
        >
          Avoid Penalties by Understanding Postmarks
        </MuiLink>
      </div>
    </Box>
  );
}
export default ImportantDates;
