import { PiLinkSimpleBold } from "react-icons/pi";
import { BiSolidHelpCircle } from "react-icons/bi";

export const generalInfoItemsList = [
  {
    subheader: "Useful Links",
    items: [
      { item: "San Bernardino County", itemLink: "#" },
      { item: "Assessor Recorder Clerk", itemLink: "#" },
      { item: "District Attorney", itemLink: "#" },
      { item: "Sheriff", itemLink: "#" },
      { item: "Board of Supervisors", itemLink: "#" },
      { item: "Tax Agency Registration", itemLink: "/forms/tax-agency-registration" },
    ],
    icon: <PiLinkSimpleBold />,
  },
  {
    subheader: "Help & Contact",
    items: [
      { item: "Frequently Asked Questions", itemLink: "/faq" },
      { item: "Hours, Locations, and Contact Numbers", itemLink: "/hours-and-locations" },
      { item: "Contact Us", itemLink: "/contact-us" },
    ],
    icon: <BiSolidHelpCircle />,
  },
];
