import ReactHtmlParser from "html-react-parser";
import DOMPurify from "dompurify";
import he from "he";

export const parseHtml = (html) => {
  if (!html || typeof html !== "string") return null;

  const decodedHighlightValue = he.decode(html);
  const sanitizedHighlightValue = DOMPurify.sanitize(decodedHighlightValue.replaceAll(`\n`, "<br>"));
  return ReactHtmlParser(sanitizedHighlightValue);
};

export const isExternalLink = (link) => {
  // if the link starts with a / and a letter OR is "#"
  return !link.match(/^\/[a-zA-Z]|#$/);
};
