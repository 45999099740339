import React from 'react'
import { Box, List, ListItemButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DefaultLayout from '../../components/layouts/default-layout';
import { govHubSubPath } from '../../utils/utils.js';
function GovHubServiceLinks() {

    const linkList=[
        { linkTitle: "Pay My Property Tax", linkDestination: "/" + govHubSubPath + "/property-tax" },
        { linkTitle: "Checkout", linkDestination: "/" + govHubSubPath +"/checkout" },
        { linkTitle: "My Dashboard", linkDestination: "/" + govHubSubPath + "/my-dashboard" },
        { linkTitle: "Penalty Cancellation Request Form", linkDestination: "/" + govHubSubPath + "/property-tax/forms/penalty-waiver-request" },
        { linkTitle: "Bulk Tax Payments", linkDestination: "/" + govHubSubPath + "/property-tax/upload" },
        { linkTitle: "Mobile Home Tax Clearance", linkDestination: "/" + govHubSubPath + "/property-tax/forms/tax-certificate-form" },
    ];


    return (<DefaultLayout title={"Tax Services"} >
        <div className="container">
            <Box sx={{ height: '80vh' }} className="py-4">
                <Typography variant="h5" className="px-3 px-md-0">
                    Tax Services...
                </Typography>
                <List>
                    {linkList.map((link, i) => (
                        <ListItemButton
                            key={i}
                            className="d-flex justify-content-between"
                            divider
                            component={Link}
                            to={link.linkDestination}
                            target=""
                        >
                            <>
                                {link.linkTitle}

                                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                            </>
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        </div>
    </DefaultLayout>);
}

export default GovHubServiceLinks;