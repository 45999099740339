import React, { useEffect, useState } from "react";
import ExecMemberCard from "../../components/exec-components/exec-member-card";
import { getDataFromCmsApi } from "../../api/contentful-service";

const ExecTeam = () => {
  const [atcInformation, setAtcInformation] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    // get team members info
    const result = await getDataFromCmsApi("TeamMember");
    setAtcInformation(result);
  };

  return (
    <div className="justify-content-center align-items-center container pb-5">
      <h1 className="display-4 text-center m-2 m-md-4 py-3">Meet ATC&apos;s Executive Team</h1>
      <small>
        ATC Mason&apos;s Executive Management Team has over 400 years of combined expertise in accounting, finance,
        information technology, law, auditing of County departments, offices, districts and special districts,
        private firm & public agency leadership, private & public strategic investing and money management,
        tax collection, accurate processing and disbursing of County employee wages and payroll related taxes,
        property tax apportionment and redevelopment successor entity fund management. ATC leadership has the
        power and expertise to effectively serve the people of San Bernardino County!
      </small>
      <div className="py-4">
        <div className="d-flex justify-content-center">
          {atcInformation &&
            atcInformation
              .filter((item) => item.position === "Auditor-Controller/Treasurer/Tax Collector")
              .map((member, index) => <ExecMemberCard key={index} member={member} />)}
        </div>

        <div className="d-flex flex-wrap justify-content-center">
          {atcInformation &&
            atcInformation
              .filter((item) => item.position === "Assistant Auditor-Controller/Treasurer/Tax Collector")
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((member, index) => <ExecMemberCard key={index} member={member} />)}
        </div>

        <div className="d-flex flex-wrap justify-content-center">
          {atcInformation &&
            atcInformation
              .filter((item) => item.division !== "Administration")
              .map((member, index) => (
                <div key={member}>
                  <ExecMemberCard key={index} member={member} />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};
export default ExecTeam;
