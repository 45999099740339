import { RiAdminFill } from "react-icons/ri";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaMoneyBillTransfer, FaMoneyBillTrendUp } from "react-icons/fa6";
import { BsFileEarmarkCheckFill, BsPersonWorkspace } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";

export const divisionItemsList = [
  {
    subheader: "Administration",
    subheaderLink: "/administration",
    items: [],
    icon: <RiAdminFill />,
  },
  {
    subheader: "Treasurer",
    subheaderLink: "/treasurer",
    items: [
      { item: "Management of Investment Pool", itemLink: "/treasurer/investment-pool" },
      { item: "Broker/Dealer Application", itemLink: "/treasurer/rfi" },
      { item: "Legally Mandated Duties", itemLink: "/treasurer/legally-mandated-duties" },
      { item: "Reports", itemLink: "/search/documents?divisionName=Treasurer" },
    ],
    icon: <FaHandHoldingUsd />,
  },
  {
    subheader: "Information Technology",
    subheaderLink: "/information-technology",
    items: [],
    icon: <GrTechnology />,
  },
  {
    subheader: "Disbursements",
    subheaderLink: "/disbursements",
    items: [
      { item: "Account Payable", itemLink: "#" },
      { item: "Central Payroll", itemLink: "#" },
    ],
    icon: <FaMoneyBillTransfer />,
  },
  {
    subheader: "Internal Audits",
    subheaderLink: "/internal-audits",
    items: [
      { item: "Report Fraud, Waste, and Abuse", itemLink: "internal-audits/report-fraud-waste-abuse" },     
      { item: "Independence of the Auditor Function", itemLink: "internal-audits/independence_of_the_auditor_function" },
    ],
    icon: <BsFileEarmarkCheckFill />,
  },
  {
    subheader: "Revenue Recovery",
    subheaderLink: "/revenue-recovery",
    items: [],
    icon: <FaMoneyBillTrendUp />,
  },
  {
    subheader: "SAP Center of Excellence",
    subheaderLink: "/sap",
    items: [],
    icon: <BsPersonWorkspace />
  },
  {
    subheader: "Controller",
    subheaderLink: "/controller",
    items: [
      { item: "Financial Reports", itemLink: "#" },
      { item: "Awards", itemLink: "#" }
    ],
    icon: <MdManageAccounts />,
  },
];