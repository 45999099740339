import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import "./static-page.scss";


StaticPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired
};


const largeWidthPage = {
  xs: "90vw",
  lg: "80vw",
  xl: "65vw",
};

function StaticPageContainer({ title, children }) {
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        justifyContent: "center",
        lineHeight: "1.7",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          border: "none",
          width: largeWidthPage,
          padding: "35px 30px 55px",
          fontSize: { xs: "medium", sm: "large" },
        }}
      >
        <h1 className="display-5 text-center mb-lg-4 pb-4 text-break">{title}</h1>
        <div className="page-content">{children}</div>
      </Card>
    </div>
  );
}

export default StaticPageContainer;
