import React from "react";
import DivisionContact from "../../components/division-homepage/division-contact";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionServices from "../../components/division-homepage/division-services";
import AlertService from "../../components/alert-service/alert-service";
import { FaLink } from "react-icons/fa";
import PropTypes from "prop-types";

const iconList = [FaLink];

function RVRHome({ division }) {
  return (
    <div>
      <AlertService divisionCode={division.divisionAbbreviation} />
      <DivisionServices
        divisionAbbreviation={division.divisionAbbreviation}
        iconList={iconList}
        sectionTitle="Our Services"
      />
      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/revenue-recovery/about"
      />
      <DivisionContact
        divisionAbbreviation={division.divisionAbbreviation}
        sectionTitle={"Contact Revenue Recovery"}
      />
    </div>
  );
}

RVRHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default RVRHome;
